import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  reactive,
  ref,
  toRaw,
  watch,
  nextTick,
  inject,
} from "vue"
import SvgIcon from "@/components/SvgIcon";
import {RouteLocation, RouteLocationRaw, useRouter, onBeforeRouteLeave} from "vue-router"
import {useStore} from "vuex"
import NotesList from "@/components/NotesList"
import {IData, IFieldErrors, IFlowStep, ITicket, ITicketSchemaByRows, ITicketSchemaItem, ITicketTag} from "@/types"
import NotesPanel from "@/components/NotesPanel"
import {useFetchState} from "@/composables/useFetchState"
import {useI18n} from "vue-i18n"
import TicketService from "@/services/ticket_service"
import TicketBaseAssigned_to from "@/components/TicketFields/TicketBaseAssigned_to.vue"
import TicketBaseDescription from "@/components/TicketFields/TicketBaseDescription.vue"
import TicketBaseEmail_notify from "@/components/TicketFields/TicketBaseEmail_notify.vue"
import TicketBaseRequired_by from "@/components/TicketFields/TicketBaseRequired_by.vue"
import TicketBaseStage from "@/components/TicketFields/TicketBaseStage.vue"
import TicketBaseTags from "@/components/TicketFields/TicketBaseTags.vue"
import TicketCustomChoice from "@/components/TicketFields/TicketCustomChoice.vue"
import TicketCustomDate from "@/components/TicketFields/TicketCustomDate.vue"
import TicketCustomFloat from "@/components/TicketFields/TicketCustomFloat.vue"
import TicketCustomString from "@/components/TicketFields/TicketCustomString.vue"
import TicketCustomText from "@/components/TicketFields/TicketCustomText.vue"
import {useTicketActions} from "@/composables/useTicketActions"
import {useFormErrors} from "@/composables/useFormErrors"
import { useScroll } from "@vueuse/core"
import VMenu from '@/components/Menus/VMenu.vue'
import FloatingContainer from '@/components/Popups/FloatingContainer.vue'
import MenuTicket from "@/components/Menus/MenuTicket.vue"
import VMenuSeparator from '@/components/Menus/VMenuSeparator.vue'
import VMenuItem from '@/components/Menus/VMenuItem.vue'
import {useFloatingExists} from "@/composables/useFloatingExists"
import { autorefreshBlockAdd, autorefreshBlockRemove } from "@/composables/useFetchState"
import {useScreenDocumentTitle} from "@/composables/useScreenDocumentTitle"
import ContentPanels from "@/components/ContentPanels.vue"
import {useCurrentScreen} from "@/composables/useCurrentScreen"
import {useAppUtils} from "@/composables/useAppUtils"
import _cloneDeep from "lodash/cloneDeep"
import _throttle from 'lodash/throttle'
import TicketColorPicker from "@/components/Popups/TicketColorPicker.vue"
import {useBrowserStorage} from "@/composables/useBrowserStorage"
import {useHighlight} from "@/composables/useHighlight"
import GlobalService from "@/services/global_service"
import {useResizeObserver} from "@/composables/useResizeObserver"
import TagsService from "@/services/tags_service"

let previousRouteObj: Partial<RouteLocation> | null = null

export default defineComponent({
  name: "TicketScreen",
  components: {
    SvgIcon,
    NotesPanel,
    NotesList,
    TicketBaseAssigned_to,
    TicketBaseDescription,
    TicketBaseEmail_notify,
    TicketBaseRequired_by,
    TicketBaseStage,
    TicketBaseTags,
    TicketCustomChoice,
    TicketCustomDate,
    TicketCustomFloat,
    TicketCustomString,
    TicketCustomText,
    VMenu,
    FloatingContainer,
    MenuTicket,
    VMenuSeparator,
    VMenuItem,
    ContentPanels,
    TicketColorPicker,
  },
  props: {
    ticketId: {
      type: String,
      required: true
    },
    clone: {
      type: String,
      required: false
    },
    viewNoteId: {
      type: String,
      required: false
    },
    nextPrev: {
      type: String,
      required: false
    },
    project: {
      type: String,
      required: false
    },
    serverParam: {
      type: String,
      required: false
    },
  },

  beforeRouteEnter(to, from) {
    previousRouteObj = from.fullPath === '/' ? null : {
      name: from.name,
      params: {... from.params },
      query: { ... from.query }
    }
    console.log('beforeRouteEnter goBack', from, to, previousRouteObj)
  },

  setup(props) {

    console.log('TicketScreen setup', props)

    const moduleName = 'ticket'
    const storeNamespace = 'ticket'
    //const resizablePanel = 'left'
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()

    const storeTicket = toRaw(store.getters[storeNamespace + '/ticket'])
    const ticket = ref<IData>(storeTicket ? _cloneDeep(storeTicket) : {})
    const initialTicket = ref<IData>(ticket.value === null ? null : _cloneDeep(ticket.value))
    const isShowTicketId = computed(() => store.getters['settings/show_ticket_id'])

    //const schema = computed(() => store.getters[storeNamespace + '/schema'])
    const schemaByRows = computed<ITicketSchemaByRows | null>(() => store.getters[storeNamespace + '/schemaByRows'])
    const flowSteps = computed<IFlowStep[]>(() => store.getters['ticket/flowSteps'])
    const customFieldsValues = ref<IData | null>(_cloneDeep({
      ...toRaw(store.getters[storeNamespace + '/customFieldsValues'])
    }))
    const initialCustomFieldsValues = ref<IData | null>(customFieldsValues.value === null ? null : _cloneDeep(customFieldsValues.value))

    const schemaByFieldNames = computed(() => {
      const result = {};
      ['main', 'advanced'].forEach(section => {
        if (schemaByRows.value && Array.isArray(schemaByRows.value[section])) {
          schemaByRows.value[section].forEach(rowArray => {
            rowArray.forEach(item => {
              result[item.name] = item
            })
          })
        }
      })
      return result
    })

    const fieldErrors = ref<IFieldErrors>({})
    const refNotesPanel = ref<InstanceType<typeof NotesPanel> | null>(null)
    //const refContentPanels = ref<InstanceType<typeof ContentPanels> | null>(null)
    const loadingScreenData = ref(false)
    const emitter: any = inject('emitter')
    //let resizeObserver: any = null

    const eventLogTooltipContainer = document.body
    const eventLog = computed<string[]>(() => store.getters[storeNamespace + '/eventLog'])
    const eventLogTooltip = computed(() => eventLog.value.reduce(
      (accumulator, currentValue) => accumulator + `<div class="event-log">${currentValue}</div>`,
      '')
    )

    const resetInitialValues = () => {
      initialTicket.value = _cloneDeep(ticket.value)
      initialCustomFieldsValues.value = _cloneDeep(customFieldsValues.value)
      if (formFieldsBase.description) {
        formFieldsBase.description.isChanged = false
      }

    }

    const resetScreenData = async () => {
      console.log('TicketScreen resetScreenData')
      store.commit(moduleName + '/set_ticket', null)
      refNotesPanel.value?.resetData()
      ticket.value = {}
      customFieldsValues.value = {}
      ticketTags.value = []
      fieldErrors.value = {}
      destroyFormErrors()
      resetInitialValues()
      loadingScreenData.value = true
    }

    const confirmContinueHandler = () => {
      console.log('ConfirmContinue TicketScreen ContinueWithoutSavingConfirmed')
      fieldErrors.value = {}
      destroyFormErrors()
      resetInitialValues()
    }

    /*
        fieldErrors.value = {
          f1: "Обязательное поле",
          f2: "Обязательное поле",
          f3: "Обязательное поле",
          //f4: "Обязательное поле",
          f5: "Обязательное поле",
          f6: "Обязательное поле",
          f7: "Обязательное поле",
          f8: "Обязательное поле",
          f9: "Обязательное поле",
          f10: "Обязательное поле",
          f11: "Обязательное поле",
          f12: "Обязательное поле",
          f13: "Обязательное поле",
          f14: "Обязательное поле",
          f15: "Обязательное поле",
          f16: "Обязательное поле",
          f17: "Обязательное поле",
          f18: "Неверное значение",
          f19: "Обязательное поле",
        }
    */

    console.log('TicketScreen fieldErrors', fieldErrors)

    const archiveButtonLabel = computed(() => ticket.value?.archived ? t('Restore ticket') : t('Archive ticket'))
    const isNewTicket = computed(() => store.getters[storeNamespace + '/isNewTicket'])
    const isReadonlyTicket = computed(() => {
      console.log('isReadonlyTicket', store.getters[storeNamespace + '/isReadonlyTicket'] || (ticket.value && Object.prototype.hasOwnProperty.call(ticket.value, 'isEdit') ? !ticket.value.isEdit : false), '|', store.getters[storeNamespace + '/isReadonlyTicket'], ticket.value, ticket.value?.isEdit)
      return store.getters[storeNamespace + '/isReadonlyTicket'] || (ticket.value && Object.prototype.hasOwnProperty.call(ticket.value, 'isEdit') ? !ticket.value.isEdit : false)
    })
    const projectId = computed(() => ticket.value && ticket.value.flow_id ? ticket.value.flow_id : '')

    const formFieldsCustom = reactive<any>({})
    const formFieldsBase = reactive<any>({})

    const hasChanges = computed(() => {
      if (!ticket.value || !customFieldsValues.value || isReadonlyTicket.value) {
        return false
      }
      // tags
      // users

      const currentTicketData = _cloneDeep(ticket.value)
      const initialTicketData = _cloneDeep(initialTicket.value)

      const currentCustomFieldsData = _cloneDeep(customFieldsValues.value)
      const initialCustomFieldsData = _cloneDeep(initialCustomFieldsValues.value)

      const ticketUsersNew = Array.isArray(currentTicketData.ticket_users) ? JSON.stringify(currentTicketData.ticket_users.sort()) : currentTicketData.ticket_users
      const ticketUsersOld = Array.isArray(initialTicketData.ticket_users) ? JSON.stringify(initialTicketData.ticket_users.sort()) : initialTicketData.ticket_users

      delete currentTicketData.ticket_users
      delete initialTicketData.ticket_users

      const tagsNew = currentTicketData.tags ? JSON.stringify(currentTicketData.tags.split(', ').sort()) : ''
      const tagsOld = initialTicketData.tags ? JSON.stringify(initialTicketData.tags.split(', ').sort()) : ''

      delete currentTicketData.tags
      delete initialTicketData.tags

      delete currentTicketData.tmpColor
      delete initialTicketData.tmpColor

      console.log('TicketScreen ticket_users hasChanges', ticketUsersNew !== ticketUsersOld, ticketUsersNew, ticketUsersOld)

      console.log('TicketScreen ticket tags hasChanges', tagsNew !== tagsOld, tagsNew, tagsOld)

      console.log('TicketScreen formFieldsBase.description hasChanges', formFieldsBase.description?.isChanged)

      console.log('TicketScreen ticket form hasChanges', JSON.stringify(currentTicketData))
      console.log('TicketScreen ticket form hasChanges', JSON.stringify(initialTicketData))
      console.log('TicketScreen ticket form hasChanges', JSON.stringify(currentTicketData) !== JSON.stringify(initialTicketData))

      console.log('TicketScreen CustomFields hasChanges', JSON.stringify(currentCustomFieldsData))
      console.log('TicketScreen CustomFields hasChanges', JSON.stringify(initialCustomFieldsData))
      console.log('TicketScreen CustomFields hasChanges', JSON.stringify(currentCustomFieldsData) !== JSON.stringify(initialCustomFieldsData))

      return formFieldsBase.description?.isChanged ||
        tagsNew !== tagsOld ||
        ticketUsersNew !== ticketUsersOld ||
        JSON.stringify(currentTicketData) !== JSON.stringify(initialTicketData) ||
        JSON.stringify(currentCustomFieldsData) !== JSON.stringify(initialCustomFieldsData)
    })

    watch(() => hasChanges.value, value => {
      console.log('TicketScreen watch hasChanges.value', value)
      //emit('hasChanges', hasChanges.value, (props.isNew ? 'top' : 'bottom'))
      store.commit('ticket/setTicketFormChanged', value)
      if (value) {
        autorefreshBlockAdd('TicketForm_hasChanges', {id: ticket.value?.id})
      } else {
        autorefreshBlockRemove('TicketForm_hasChanges')
      }
    })


    watch(isNewTicket, async (value) => {
      console.log('watch isNewTicket', value)
      if (value) {
        cancelAutorefresh()
        store.commit(storeNamespace + '/setClone', '')
        await nextTick()
        refTicketTitle.value?.focus()
      } else {
        initAutorefresh()
        // await nextTick()
        // console.log('watch isNewTicket focusTopNoteForm', refNotesPanel.value, refNotesPanel.value?.focusTopNoteForm)
        // if (refNotesPanel.value) {
        //   refNotesPanel.value.focusTopNoteForm()
        // }
      }
    })

    // watch(ticket, () => {
    //   console.log('TicketScreen watch ticketRef', ticket.value)
    // }, {deep: true})

    watch(() => store.getters[storeNamespace + '/ticket'], async (curValue, prevValue) => {
      store.commit(moduleName + '/setNextPrev', '')
      ticket.value = _cloneDeep(curValue)
      initialTicket.value = _cloneDeep(ticket.value)
      console.log('TicketScreen watch ticket', ticket.value, curValue, prevValue)
      //store.commit('setLoaders', [])
      await nextTick()
      if (curValue?.id) {
        loadingScreenData.value = false
        hidePanelsLoaders()
        await nextTick()
        if (curValue?.id !== prevValue?.id) {
          console.log('watch ticket focusTopNoteForm', isNewTicket.value, refNotesPanel.value, refNotesPanel.value?.focusTopNoteForm)
          if (isNewTicket.value) {
            if (refTicketTitle.value) {
              refTicketTitle.value.focus()
            }
          } else {
            if (refNotesPanel.value) {
              await refNotesPanel.value.focusTopNoteForm()
            }
          }
        }
      }
    }, {deep: true})

    watch(() => store.getters[storeNamespace + '/customFieldsValues'], (curValue) => {
      console.log('TicketScreen watch f15', curValue.f15)
      console.log('TicketScreen watch customFieldsValues', JSON.stringify(curValue))
      customFieldsValues.value = _cloneDeep(curValue)
      initialCustomFieldsValues.value = _cloneDeep(customFieldsValues.value)
      console.log('TicketScreen watch customFieldsValues', customFieldsValues.value)
    }, {deep: true})

    // const loadingsState = reactive({
    //   loadingFull: false,
    //   loadingLeft: false,
    //   loadingRight: false,
    // })

    const {
      fetchStateInit,
      fetchState,
      initAutorefresh,
      cancelAutorefresh,
      updateLocationHref,
    } = useFetchState({
      storeNamespace: moduleName,
    })

    const {
      showResponseSuccessMessage,
      isMayContinue,
      getAuthUser,
      showPanelsLoaders,
      hidePanelsLoaders,
      // showFullPanelsLoader,
      // hideFullPanelsLoader,
      showLeftPanelLoader,
      // hideLeftPanelLoader,
      // showRightPanelLoader,
      // hideRightPanelLoader,
      hideStartLoader,
      getMinDarkColor,
      showWarning,
      vConfirm,
      vEscape,
    } = useAppUtils()

    const minDarkColor = getMinDarkColor()

    const afterFetchStateInit = () => {
      //loadingsState.loadingFull = false
      hidePanelsLoaders()
      store.commit(moduleName + '/setClone', '')
      store.commit(moduleName + '/setNextPrev', '')
    }

    useCurrentScreen(moduleName)
    useScreenDocumentTitle(moduleName)

    onBeforeRouteLeave(async () => {
      if (!await isMayContinue()) { return false }
    })

/*
    watch(() => store.getters[storeNamespace + '/ticketId'], async (curValue, prevValue) => {
      console.log('TicketScreen watch ticketId', curValue, prevValue, props.ticketId, store.getters[storeNamespace + '/ticketId'])
      if (prevValue) {
        showPanelsLoaders()
      }
    })
*/


    // watch(() => refContentPanels.value?.isResizing, value => {
    //   console.log('TicketScreen watch isResizing', value)
    //   destroyFormErrors()
    // })

    onBeforeMount(async () => {
      console.log('TicketScreen onBeforeMount getAllTags', store.getters.tags)
      TagsService.getAllTags()

      console.log('TicketScreen onBeforeMount resetScreenData', ticket.value?.id !== props.ticketId, ticket.value?.id, props.ticketId)
      if (ticket.value?.id !== props.ticketId) {
        await resetScreenData()
        await nextTick()
      }
    })

    const scrollToFirstError = () => {
      const firstErrorField = document.querySelector('.ticket-form-content [data-error-fieldname]')
      if (firstErrorField) {
        firstErrorField.scrollIntoView({
          block: "center",
          inline: "nearest",
          behavior: "smooth",
        })
      }
    }

    const scrollToNotes = () => {
      const element = document.querySelector('.right-panel-content')
      if (element) {
        element.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        })
      }
    }

    // if ('ResizeObserver' in self) {
    //   resizeObserver = new window['ResizeObserver']((entries) => {
    //     for (const entry of entries) {
    //       console.log('TicketScreen ResizeObserver entry', entry, entry.target === refScrollableContainerLeftPanel.value)
    //       if (entry.target === refScrollableContainerLeftPanel.value) {
    //         hideFormErrorsHints()
    //       }
    //     }
    //
    //   });
    // }

    let initialTicketId = ''
    const { highlightNote } = useHighlight()
    let highlightNoteId = ''
    watch(() => props.viewNoteId, value => {
      if (value) {
        highlightNoteId = value
      }
    }, {immediate: true})

    onMounted(async () => {
      console.log('TicketScreen onMounted', ticket.value)
      initialTicketId = props.ticketId

      resetInitialValues()
      try {
        await fetchStateInit(props)
        afterFetchStateInit()
        if (isNewTicket.value) {
          await nextTick()
          refTicketTitle.value?.focus()
        } else {
          initAutorefresh()
        }

        const quickAddError = toRaw(store.getters[storeNamespace + '/quickAddError'])

        if (quickAddError?.ticket && quickAddError?.errors && quickAddError.ticket == props.ticketId) {
          fieldErrors.value = { ...quickAddError?.errors }

          //if (!isFormErrorFieldsRegistered) {
          registerFormErrorFields()
          //}

          checkFormErrors()
          initialTicket.value.title = ''
          scrollToFirstError()
        }

        store.commit(moduleName + '/setQuickAddError', {})

        hideStartLoader()
        emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
        emitter.on('ContinueWithoutSavingConfirmed', confirmContinueHandler)

        await highlightNote(highlightNoteId, true)

        // if (resizeObserver && refScrollableContainerLeftPanel.value) {
        //   resizeObserver.observe(refScrollableContainerLeftPanel.value)
        // }

        //refScrollableContainer.value = refContentPanels.value?.refScreenPanelLeft //document.querySelector('.screen-panel-left')
      } catch (e) {
        console.log('TicketScreen onMounted error', e, JSON.stringify(props))
      }
    })


    //let ticketChangedInsideScreen = false

/*
    const goBack = async (ticketId?) => {
      // store.commit('addLoader', {to: '.screen-container', type: 'app-loader', opacity: 0.1})
      // await nextTick()
      if (!await isMayContinue()) { return false }
      fieldErrors.value = {}
      destroyFormErrors()
      console.log('goBack', ticketId, initialTicketId, store.getters.project?.id, JSON.stringify(previousRouteObj))

      if (previousRouteObj && previousRouteObj.name !== 'Board' && store.getters.project?.id && store.getters.project?.id === previousRouteObj.params?.projectId) {

        if (ticketId !== undefined && ticketId === initialTicketId) {
          // if (!previousRouteObj.params) {
          //   previousRouteObj.params = {}
          // }
          // previousRouteObj.params.ticket = ticketId

          if (!previousRouteObj.query) {
            previousRouteObj.query = {}
          }
          previousRouteObj.query.ticket = ticketId
        }
        console.log('goBack 2', JSON.stringify(previousRouteObj))
        await router.push(previousRouteObj as RouteLocationRaw)
      } else {
        await router.push({
          name: 'Board',
          params: {projectId: store.getters.project?.id || null}
        })
      }

    }
*/

    const goBack = async (ticketId?) => {
      // store.commit('addLoader', {to: '.screen-container', type: 'app-loader', opacity: 0.1})
      // await nextTick()
      if (!await isMayContinue()) { return false }
      fieldErrors.value = {}
      destroyFormErrors()
      console.log('goBack', ticketId, initialTicketId, store.getters.project?.id, JSON.stringify(previousRouteObj))

      if (previousRouteObj) {

        if (!previousRouteObj.query) {
          previousRouteObj.query = {}
        }
        previousRouteObj.query.ticket = ticketId || 'all' // строка 'all' учитывается в useParams

        if (previousRouteObj.name !== 'Dashboard') {
          if (!previousRouteObj.params) {
            previousRouteObj.params = {}
          }
          previousRouteObj.params.projectId = store.getters.project?.id || null
        }
        console.log('goBack 2', JSON.stringify(previousRouteObj))
        await router.push(previousRouteObj as RouteLocationRaw)

      } else {
        await router.push({
          name: 'Board',
          params: {projectId: store.getters.project?.id || null}
        })
      }

    }

    const cancelTicket = async () => {
      resetInitialValues()
      await nextTick()
      await goBack()
    }

    const changeTitleHandler = (event) => {
      console.log('changeTitleHandler', event, ticket, ticket.value, ticket.value)
      if (ticket.value) {
        ticket.value.title = event.target.innerHTML
      }
    }

    const isSavingTicket = ref(false)
    const isSavedTicket = ref(false)

    const isNotesFilesUploadInProgress = computed(() => store.getters[storeNamespace + '/isNoteFormsUploading'])
    const isNoteFormsChanged = computed(() => store.getters[storeNamespace + '/isNoteFormsChanged'])

    const {setTicketIdToHighlight} = useHighlight()

    const saveTicket = async (event) => {
      console.log('TicketScreen saveTicket', ticket.value, formFieldsBase.description?.getData(), event)
      if (!ticket.value) {
        return
      }
      event.target.blur()

      console.log('TicketScreen saveTicket isUploadInProgress', isNotesFilesUploadInProgress.value)
      if (!(event.ctrlKey || event.metaKey)) {
        if (isNotesFilesUploadInProgress.value) {
          showWarning(t('please wait for the file(s) to upload'), {id: 'wait_for_the_file_upload'})
          return
        }
        if (isNoteFormsChanged.value && !await vConfirm(t('You forgot to save your Note.<br>Click Ok to continue without saving the note.'))) {
          return
        }
        //emitter.emit('ContinueWithoutSavingConfirmed')
      }

      fieldErrors.value = {}
      destroyFormErrors()
      try {
        isSavingTicket.value = true

        if (formFieldsBase.tags) {
          ticketTags.value = await formFieldsBase.tags.getTags()
          await nextTick()
        }

        const result = await TicketService.saveTicket({
          ..._cloneDeep(ticket.value),
          ..._cloneDeep(customFieldsValues.value),
          description: formFieldsBase.description?.getData()
        } as ITicket)

        //await valert(result.message)
        // await store.dispatch('addNotification', {
        //   text: result.message
        // })
        await showResponseSuccessMessage(result)
        resetInitialValues()
        await nextTick()
        if (!(event.ctrlKey || event.metaKey)) {
          isSavingTicket.value = false
          isSavedTicket.value = true
          setTimeout(() => { isSavedTicket.value = false }, 500)
          const ticketId = result?.xdata?.id
          if (ticketId && (!previousRouteObj || previousRouteObj?.name === 'Board')) {
            setTicketIdToHighlight(ticketId)
          }
          emitter.emit('ContinueWithoutSavingConfirmed')
          await nextTick()
          await goBack(ticketId)
        } else {
          store.commit('ticket/setIsNewTicket', false)
          await fetchState({without_notes: 1})
          isSavingTicket.value = false
          isSavedTicket.value = true
          setTimeout(() => { isSavedTicket.value = false }, 500)
        }

      } catch (data:any) {
        console.log('TicketScreen saveTicket error', data, data.isError, data.xdata)
        isSavingTicket.value = false
        isSavedTicket.value = false

        if (data.isError && data?.xdata?.errors) {

          fieldErrors.value = { ... data.xdata.errors }

          //if (!isFormErrorFieldsRegistered) {
            registerFormErrorFields()
          //}

          checkFormErrors()
          scrollToFirstError()
        }
      }
    }

    const archiveTicketHandler = async (ticketId: string) => {
      if (!await isMayContinue()) { return false }
      const result = await archiveTicket(ticketId)
      console.log('TicketScreen archiveTicket', ticket.value, result)
      if (!result.isError && ticket.value) {
        ticket.value.archived = !ticket.value.archived
        initialTicket.value = _cloneDeep(ticket.value)
      }
    }


    const cloneTicketHandler = async (ticketId: string) => {
      if (!await isMayContinue()) { return false }
      fieldErrors.value = {}
      destroyFormErrors()
      await cloneTicket(ticketId)
    }


    const removeTicketHandler = async (ticketId: string, ticketName: string) => {
      fieldErrors.value = {}
      destroyFormErrors()

      // const tempElement = document.createElement('span')
      // tempElement.innerText = ticketName
      // ticketName = tempElement.innerHTML
      // tempElement.remove()

      const result = await removeTicket(ticketId, vEscape(ticketName))
      console.log('TicketScreen removeTicket', ticket.value, result)
      if (result) {
        //await valert(result.message)
        // await store.dispatch('addNotification', {
        //   text: result.message
        // })
        await showResponseSuccessMessage(result)
        if (!result.isError) {
          resetInitialValues()
          await nextTick()
          //await goBack('all')
          await goBack()
        }
      }
    }

    const {
      cloneTicket,
      archiveTicket,
      removeTicket,
      assignTicketToMe,
    } = useTicketActions()

    const gotoTicket = async (direction: string) => {
      if (!await isMayContinue()) { return false }
      // ticketChangedInsideScreen = true
      // if (previousRouteObj?.params) {
      //   delete previousRouteObj.params.ticket
      // }
      // if (previousRouteObj?.query) {
      //   delete previousRouteObj.query.ticket
      // }
      await resetScreenData()
      await nextTick()
      showPanelsLoaders()

      await router.push({name: 'Ticket', params: {id: props.ticketId}, query: {np: direction}})

/*
      await updateLocationHref({name: 'Ticket', params: {id: props.ticketId}, query: {np: direction}}, true)
      await fetchState({np: direction})
*/


      //const response = await fetchState({np: direction})
      //await router.push({name: 'Ticket', params: {id: response?.xdata?.ticket_id}})
      //await router.push({name: 'Ticket', params: {id: 'test'}})
    }

    // const
    // const isBaseFieldName = (name: string) => {
    //   const
    //   return
    // }

    const getFieldComponentName = (schemaItem: ITicketSchemaItem) => {
      return schemaItem.is_base ? 'ticket-base-' + schemaItem.name : 'ticket-custom-' + schemaItem.type
    }

    const changeReadonlyTicket = (event) => {
      store.commit(storeNamespace + '/setIsReadonlyTicket', event.target.checked)
    }

    const removeFieldError = (fieldName: string) => {
      delete fieldErrors.value[fieldName]
    }

    const refTicketTitle = ref<HTMLElement | null>(null)
    const refScrollableContainerLeftPanel = ref<HTMLElement | null>(null)
    const refScrollableContainerRightPanel = ref<HTMLElement | null>(null)

    const { y: scrollTopLeftPanel, arrivedState: arrivedStateLeftPanel } = useScroll(
      refScrollableContainerLeftPanel,
      {
        offset: { bottom: 15, }
      }
    )
    const { y: scrollTopRightPanel, arrivedState: arrivedStateRightPanel } = useScroll(refScrollableContainerRightPanel)

    // watch(() => refContentPanels.value?.resizablePanelWidth, value => {
    //   console.log('TicketScreen watch resizablePanelWidth', value)
    //   isColorPickerButtonVisible.value = value > 500
    // })

    // const checkColorPickerButtonVisible = (entry) => {
    //   console.log('checkColorPickerButtonVisible')
    //
    //   if (isNewTicket.value) {
    //     return
    //   }
    //   //isColorPickerButtonVisible.value = entry.contentRect.width > 500
    //   //const buttonEl = refColorPickerButton.value ? (refColorPickerButton.value as HTMLElement) : null
    //   const buttonEl = document.getElementById('ticketColorButton')
    //   if (buttonEl) {
    //     //buttonEl.style.display = 'none !important'
    //     buttonEl.classList[entry.contentRect.width > 500 ? 'remove' : 'add']('hidden')
    //     //console.log('checkColorPickerButtonVisible', buttonEl?.className, buttonEl, isColorPickerButtonVisible.value, entry.contentRect.width > 500)
    //     //buttonEl.style.display = entry.contentRect.width > 500 ? 'inline-flex !important' : 'none !important'
    //   }
    //
    // }

    // const {
    //   addResizeObserverItem,
    //   removeResizeObserverItem,
    // } = useResizeObserver()
    //
    // let resizeObserverItemUid
    //
    // onMounted(() => {
    //   resizeObserverItemUid = addResizeObserverItem(refScrollableContainerLeftPanel?.value, checkColorPickerButtonVisible)
    // })
    //
    // onBeforeUnmount(() => {
    //   removeResizeObserverItem(resizeObserverItemUid)
    // })

    // let resizeObserver: any = null
    //
    // if ('ResizeObserver' in self) {
    //   resizeObserver = new window['ResizeObserver']((entries) => {
    //     for (const entry of entries) {
    //       if (entry.target === refScrollableContainerLeftPanel.value) {
    //         checkColorPickerButtonVisible()
    //       }
    //     }
    //
    //   });
    //
    //   onMounted(() => {
    //     if (resizeObserver && refScrollableContainerLeftPanel.value) {
    //       resizeObserver.observe(refScrollableContainerLeftPanel.value)
    //     }
    //   })
    //
    //   onBeforeUnmount(() => {
    //     if (resizeObserver && refScrollableContainerLeftPanel.value) {
    //       resizeObserver.unobserve(refScrollableContainerLeftPanel.value)
    //     }
    //   })
    // }

    const {
      registerField,
      checkFormErrors,
      destroyFormErrors,
      hideFormErrorsHints,
      unregisterFormFields,
    } = useFormErrors('ticketForm', {
      refResizeObserverTarget: refScrollableContainerLeftPanel,
      onRemoveError(fieldName: string) {
        delete fieldErrors.value[fieldName]
      }
    })

    const isFieldInFirstRow = fieldName => {
      let firstRowField
      const schemaFirstRow = schemaByRows.value?.main && schemaByRows.value?.main[0]

      if (Array.isArray(schemaFirstRow)) {
        firstRowField = schemaFirstRow.find(item => item.name === fieldName)
      }

      return !!firstRowField
    }

    let isFormErrorFieldsRegistered = false
    const tippyZIndex = 11
    const registerFormErrorFields = () => {

      unregisterFormFields()

      registerField({
        fieldName: 'title',
        elementRefGetter: () => refTicketTitle,
        errorMessageGetter: () => fieldErrors.value?.title,
        tippyProps: {
          zIndex: tippyZIndex,
        }
      })

      registerField({
        fieldName: 'required_by',
        elementRefGetter: () => formFieldsBase.required_by?.getInputElement(),
        errorMessageGetter: () => fieldErrors.value?.deadline_date,
        tippyProps: {
          appendTo: () => refScrollableContainerLeftPanel.value,
          zIndex: tippyZIndex,
        }
      })

      registerField({
        fieldName: 'save_btn',
        elementRefGetter: () => refSaveBtn.value,
        errorMessageGetter: () => {
          const message = saveTicketReminder ? t('Do not forget to Save your ticket') : ''
          saveTicketReminder = false
          return message
        },
        tippyProps: {
          //appendTo: () => refScrollableContainerLeftPanel.value,
          zIndex: tippyZIndex,
        }
      })

      console.log('TicketScreen schemaByFieldNames', formFieldsCustom, schemaByFieldNames.value, schemaByRows.value)

      Object.keys(formFieldsCustom).forEach(fieldName => {

        const isFirstRow = isFieldInFirstRow(fieldName)
        const tippyProps: IData & {offset: number[]} = {
          appendTo: () => refScrollableContainerLeftPanel.value,
          zIndex: tippyZIndex,
          //offset: isFirstRow ? [-31, -3] : [0, 10],
          offset: isFirstRow ? [0, -12] : [0, 10],

          //placement: isFirstRow ? 'right' : 'top-start',
        }

        const fieldItem = schemaByFieldNames.value[fieldName]
        if (fieldItem && fieldItem.type === 'choice' && fieldItem.options.choiceType !== 'select') {
          tippyProps.offset[1] = isFirstRow ? -21 : 21
        }

        console.log('registerFormErrorFields', fieldName, formFieldsCustom[fieldName]?.getInputElement && formFieldsCustom[fieldName].getInputElement(), formFieldsCustom[fieldName]?.$refs?.inputRef)

        registerField({
          fieldName,
          elementRefGetter: () => (formFieldsCustom[fieldName]?.getInputElement && formFieldsCustom[fieldName].getInputElement()) || formFieldsCustom[fieldName]?.$refs?.inputRef,
          errorMessageGetter: () => fieldErrors.value[fieldName],
          tippyProps,
        })
      })

      isFormErrorFieldsRegistered = true
    }

    onUpdated(() => {
      if (fieldErrors.value && Object.keys(fieldErrors.value).length) {
        console.log('TicketScreen onUpdated checkFormErrors', JSON.stringify(Object.keys(fieldErrors.value)))
        checkFormErrors()
      }
    })

    onBeforeUnmount(() => {
      // if (resizeObserver && refScrollableContainerLeftPanel.value) {
      //   resizeObserver.unobserve(refScrollableContainerLeftPanel.value)
      // }
      fieldErrors.value = {}
      destroyFormErrors()
      //autorefreshBlockRemove('', {screen: 'TicketScreen'})
      cancelAutorefresh()
      emitter.off('ContinueWithoutSavingConfirmed', confirmContinueHandler)
    })

    const ticketTags = ref<ITicketTag[]>([])

    watch(() => store.getters[storeNamespace + '/ticketTags'], async (value: ITicketTag[]) => {
      ticketTags.value = _cloneDeep(value)
      await nextTick()
      initialTicket.value = _cloneDeep(ticket.value)
    })

    watch(ticketTags, (tagsValue: ITicketTag[]) => {
      if (ticket.value) {
        ticket.value.tags = _cloneDeep(tagsValue).map(item => item.key).join(', ')
      }
      console.log('TicketScreen watch tags', ticket.value, ticket.value?.tags)
    })

    const refTicketMenuButton = ref()
    //const isOpenTicketMenu = ref(false)
    const refTicketMenu = ref<InstanceType<typeof MenuTicket> | null>(null)
    const {isExist: isExistTicketMenu, triggerHandler: triggerHandlerTicketMenu} = useFloatingExists(refTicketMenu)

    const isColorPickerButtonVisible = ref(false)

    const ticketMenuButtonClickHandler = async () => {
      isColorPickerButtonVisible.value = false
      const colorPickerBtn = refColorPickerButton.value
      if (colorPickerBtn) {
        const styles = window.getComputedStyle(colorPickerBtn)
        console.log('ticketMenuButtonClickHandler colorPickerBtn', styles.display, styles.visibility)
        isColorPickerButtonVisible.value = styles.display !== 'none' && styles.visibility !== 'hidden'
      }
      await triggerHandlerTicketMenu()
    }

/*
    const ticketMenuItemClickHandler = async ({action, id}) => {
      console.log('ticketMenuItemClickHandler', action)
      switch (action) {
        case 'archive':
          await archiveTicketHandler(id)
          break
        case 'clone':
          await cloneTicketHandler(id)
          break
        case 'delete':
          await cloneTicketHandler(id)
          await removeTicketHandler(id, ticket.value?.title || '')
          break
      }
      //isOpenTicketMenu.value = false
      refTicketMenu.value.isOpened = false
    }
*/

/*
    const ticketMenuHandler = async (action) => {
      console.log('TicketScreen ticketMenuHandler', action)
      const id = props.ticketId
      switch (action) {
        case 'archive':
          await archiveTicketHandler(id)
          break
        case 'clone':
          await cloneTicketHandler(id)
          break
        case 'delete':
          await cloneTicketHandler(id)
          await removeTicketHandler(id, ticket.value?.title || '')
          break
      }
      //isOpenTicketMenu.value = false
      refTicketMenu.value.isOpened = false
      refTicketSubmenu.value.isOpened = false
    }
*/

    const ticketMenuActionHandler = async (action, ticketId, data) => {
      console.log('TicketScreen ticketMenuActionHandler', action, data)
      const id = props.ticketId
      let meAlreadyWasAssigned = false
      const authUser = getAuthUser()
      const authUserId = authUser?.id

      switch (action) {
        case 'assignme':
          if (isReadonlyTicket.value) { return }
          if (refTicketMenu.value) {
            refTicketMenu.value.isOpened = false
          }
          autorefreshBlockAdd('TicketScreen_ticketMenuAction_assignme')
          if (ticket.value) {
            if (authUserId && ticket.value) {
              if (!Array.isArray(ticket.value.ticket_users)) {
                ticket.value.ticket_users = []
              }
              if (Array.isArray(ticket.value.ticket_users)) {
                meAlreadyWasAssigned = ticket.value.ticket_users.includes(authUserId)
                if (!meAlreadyWasAssigned) {
                  ticket.value.ticket_users.push(authUserId)
                }
              }
              if (initialTicket.value && Array.isArray(initialTicket.value.ticket_users)) {
                if (!meAlreadyWasAssigned) {
                  initialTicket.value.ticket_users.push(authUserId)
                }
              }
            }
          }

          try {
            const result = await assignTicketToMe(ticketId, moduleName)
            console.log('TicketScreen ticketMenuActionHandler assignme success', result)
            await showResponseSuccessMessage(result)
            // if (isSuccessServerResponse(result)) {
            //   //store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {team: result.xdata.team || []}})
            //   //await fetchState({without_notes: 1})
            //
            // }
            // if (result && !result.isError) {
            //   if (result.message) {
            //     await store.dispatch('addNotification', {
            //       text: result.message,
            //     })
            //   }
            //   store.commit(moduleName + '/setTicketTeam', {ticketId, team: result.xdata.team || []})
            //
            // }
          } catch (e) {
            console.log('TicketScreen ticketMenuActionHandler assignme error', meAlreadyWasAssigned, e)
            if (e && e.code !== 1 && !meAlreadyWasAssigned) {
              if (Array.isArray(ticket.value?.ticket_users)) {
                ticket.value.ticket_users = ticket.value.ticket_users.filter(item => item !== authUserId)
              }
              if (Array.isArray(initialTicket.value?.ticket_users)) {
                initialTicket.value.ticket_users = initialTicket.value.ticket_users.filter(item => item !== authUserId)
              }
            }
          }

          autorefreshBlockRemove('TicketScreen_ticketMenuAction_assignme')
          break
        case 'archive':
        case 'restore':
          if (isReadonlyTicket.value) { return }
          await archiveTicketHandler(id)
          break
        case 'clone':
          await cloneTicketHandler(id)
          break
        case 'delete':
          if (isReadonlyTicket.value) { return }
          //await cloneTicketHandler(id)
          await removeTicketHandler(id, data.name)
          break
        case 'movedToProject':
          console.log('TicketScreen movedToProject', ticketId, data, previousRouteObj)
          store.commit('updateProject', {project: {id: data.projectId, name: data.projectName}});
          if (previousRouteObj?.params?.projectId) {
            previousRouteObj.params.projectId = data.projectId
          }
          await fetchState({without_notes: 1})
          break
        case 'resetView':
          await resetView()
          break
      }
      if (refTicketMenu.value) {
        refTicketMenu.value.isOpened = false
      }
    }

    const resetView = async () => {
      if (!await isMayContinue()) { return false }
      //showFullPanelsLoader()
      showLeftPanelLoader()
      GlobalService.setFields(storeNamespace, [], {
        reset: 1,
      }).finally(async () => {
        await nextTick()
        await fetchState()
        await nextTick()
        await hidePanelsLoaders()
      })
    }


    /*
        const getMenuClickEclusions = () => {
          return [refTicketMenuButton]
        }
    */

/*
    const refTicketSubmenu = ref<InstanceType<typeof VMenu> | null>(null)
    const refItem55Submenu = ref<InstanceType<typeof VMenu> | null>(null)

    const submenuActionHandler = (...args) => {
      console.log('TicketScreen submenuActionHandler', args)
    }

    const test1 = () => [refTicketSubmenu.value?.container, refItem55Submenu.value?.container]
    const test2 = () => [refItem55Submenu.value?.container]
*/

    const refColorPickerButton = ref(null)
    const refColorPicker = ref<InstanceType<typeof TicketColorPicker> | null>(null)
    const {isExist: isExistColorPicker, triggerHandler: triggerHandlerColorPicker} = useFloatingExists(refColorPicker)

    const previewColor = (color) => {
      console.log('TicketScreen previewColor', ticket.value?.id, color)
      if (ticket.value) {
        ticket.value.tmpColor = color
      }
    }

    const changeColor = (color) => {
      console.log('TicketScreen changeColor', ticket.value?.id, color)
      if (ticket.value) {
        ticket.value.color = color
      }
      delete ticket.value.tmpColor
      if (refColorPicker.value) {
        refColorPicker.value.isOpened = false
      }
    }

    const revertColor = (data: {initColor: string, lastColor: string}) => {
      console.log('TicketScreen revertColor', ticket.value?.id, data)
      if (ticket.value) {
        delete ticket.value.tmpColor
        ticket.value.color = data.initColor
      }
    }

    const previewColorMenuHandler = (id, color) => {
      previewColor(color)
    }

    const changeColorMenuHandler = async (id, color) => {
      changeColor(color)
      await nextTick()
      refTicketMenu.value.isOpened = false
    }

    const revertColorMenuHandler = (id, data) => {
      revertColor(data)
    }

    let saveTicketReminder = false
    const refSaveBtn = ref()
    const noteSavedHandler = async (note) => {
      console.log('TicketScreen noteSavedHandler', note, isNewTicket.value)
      if (isNewTicket.value) {
        saveTicketReminder = true
        //if (!isFormErrorFieldsRegistered) {
          registerFormErrorFields()
        //}
        checkFormErrors()
        await nextTick()
        if (refSaveBtn.value) {
          refSaveBtn.value.scrollIntoView()
        }
      }
    }



    const {
      getBrowserStorageItem,
      setBrowserStorageItem,
    } = useBrowserStorage()

    const advancedSectionStorageType = 'localStorage'
    const getAdvancedSectionStorageKey = (): string => 'ticket_adv_sec_expanded'

    const advancedSectionStorageValue = getBrowserStorageItem(getAdvancedSectionStorageKey(), advancedSectionStorageType)
    const isAdvancedSectionExpanded = ref(advancedSectionStorageValue === '1')

    const toggleAdvancedSection = async () => {
      // const separatorOffsetTop = (document.querySelector('.form-section-separator') as HTMLElement).offsetTop
      // const panelScrollTop = (document.querySelector('.left-panel-content') as HTMLElement).scrollTop

      //console.log('toggleAdvancedSection', separatorOffsetTop - panelScrollTop, separatorOffsetTop - panelScrollTop > 420, separatorOffsetTop, panelScrollTop)

      isAdvancedSectionExpanded.value = !isAdvancedSectionExpanded.value
      setBrowserStorageItem(getAdvancedSectionStorageKey(), isAdvancedSectionExpanded.value ? '1' : '0', advancedSectionStorageType)

      setTimeout(() => {
        let isScrollNeeded = false
        if (isAdvancedSectionExpanded.value && refScrollableContainerLeftPanel.value) {
          const firstRowOfAdvancedSection = refScrollableContainerLeftPanel.value.querySelector('.form-section-advanced .form-section-content .form-row')
          const buttonsRow = refScrollableContainerLeftPanel.value.querySelector('.ticket-form-buttons')
          if (firstRowOfAdvancedSection && buttonsRow) {
            const firstRowOfAdvancedSectionTop = firstRowOfAdvancedSection.getBoundingClientRect().top
            const buttonsRowTop = buttonsRow.getBoundingClientRect().top
            isScrollNeeded = Math.abs(firstRowOfAdvancedSectionTop - buttonsRowTop) < 40
            console.log('firstRowOfAdvancedSection', isScrollNeeded, firstRowOfAdvancedSectionTop, buttonsRowTop)
          }
        }
        if (isScrollNeeded && refScrollableContainerLeftPanel.value) {
          const firstRowOfAdvancedSection = refScrollableContainerLeftPanel.value.querySelector('.form-section-advanced .form-section-content .form-row')
          if (firstRowOfAdvancedSection) {
            //console.log('firstRowOfAdvancedSection', firstRowOfAdvancedSection.getBoundingClientRect())
            firstRowOfAdvancedSection.scrollIntoView({
              block: "center",
              inline: "nearest",
              behavior: "smooth",
            })
          }
        }
        checkDividerNeeded()
      }, 820)

    }

    const isDebugMode = !!inject('isDebugMode')
    const isShowDevTools = computed(() => isDebugMode && store.state.dev.isShowDevTools)

    watch(() => store.getters['project'], (value) => {
      console.log('TicketScreen watch store project', value?.id, value)
      unregisterFormFields()
      // fieldErrors.value = {}
      // destroyFormErrors()
    })

    const isButtonsDividerNeeded = ref(false)

    const checkDividerNeeded = () => {
      let formHasScroll = false
      if (refScrollableContainerLeftPanel.value?.scrollHeight && refScrollableContainerLeftPanel.value?.offsetHeight) {
        formHasScroll = refScrollableContainerLeftPanel.value.scrollHeight - refScrollableContainerLeftPanel.value.offsetHeight > 30
      }
      if (!formHasScroll) {
        isButtonsDividerNeeded.value = false
      } else {
        if (schemaByRows.value?.advanced && schemaByRows.value?.advanced.length && !isAdvancedSectionExpanded.value) {
          isButtonsDividerNeeded.value = !arrivedStateLeftPanel.bottom
        } else {
          isButtonsDividerNeeded.value = true
        }
      }
      console.log('TicketScreen checkDividerNeeded', formHasScroll, schemaByRows.value?.advanced && schemaByRows.value?.advanced.length && !isAdvancedSectionExpanded.value, isButtonsDividerNeeded.value)
    }

    let resizeObserverItemUid
    const {
      addResizeObserverItem,
      removeResizeObserverItem,
    } = useResizeObserver()

    const resizeObserverHandler = () => {
      checkDividerNeeded()
    }

    watch(() => arrivedStateLeftPanel.bottom, value => {
      checkDividerNeeded()
    })

    onMounted(async () => {
      //await toggleAdvancedSection()
      await nextTick()
      resizeObserverHandler()
      resizeObserverItemUid = addResizeObserverItem(refScrollableContainerLeftPanel.value, _throttle(resizeObserverHandler, 200))
    })

    onBeforeUnmount(() => {
      removeResizeObserverItem(resizeObserverItemUid)
    })

    const descriptionMentionHandler = (data) => {
      if (ticket.value && data?.userid) {
        if (!Array.isArray(ticket.value.ticket_users)) {
          ticket.value.ticket_users = []
        }
        if (Array.isArray(ticket.value.ticket_users)) {
          const ticketUserId = ticket.value.ticket_users.find(itemId => itemId == data.userid)
          if (!ticketUserId) {
            ticket.value.ticket_users.push(data.userid)
          }
        }
      }

    }

    return {
      //resizablePanel,
      isDebugMode,
      isShowDevTools,
      //getMenuClickEclusions,
      refTicketMenuButton,
      refTicketMenu,
      isExistTicketMenu,
      //refTicketSubmenu,
      //refItem55Submenu,
      //isOpenTicketMenu,

      // test1,
      // test2,

      refScrollableContainerLeftPanel, scrollTopLeftPanel, arrivedStateLeftPanel,
      refScrollableContainerRightPanel, scrollTopRightPanel, arrivedStateRightPanel,
      t,
      ticket,
      projectId,
      formFieldsBase,
      formFieldsCustom,
      //schema,
      schemaByRows,
      customFieldsValues,
      fieldErrors,
      flowSteps,
      ticketTags,
      archiveButtonLabel,
      isNewTicket,
      isReadonlyTicket,
      refTicketTitle,
      refNotesPanel,
      //isNotesFilesUploadInProgress,
      goBack,
      changeTitleHandler,
      saveTicket,
      cancelTicket,
      //archiveTicketHandler,
      //cloneTicketHandler,
      //removeTicketHandler,
      gotoTicket,
      getFieldComponentName,
      changeReadonlyTicket, // todo: remove after tests
      removeFieldError,
      ticketMenuButtonClickHandler,
      //ticketMenuItemClickHandler,
      //triggerHandlerTicketMenu,
      ticketMenuActionHandler,
      //submenuActionHandler,
      //ticketMenuHandler,
      loadingScreenData,
      hasChanges,
      isSavingTicket,
      isSavedTicket,
      //refContentPanels,
      minDarkColor,
      refColorPicker,
      refColorPickerButton,
      isExistColorPicker,
      triggerHandlerColorPicker,
      previewColor,
      changeColor,
      revertColor,
      previewColorMenuHandler,
      changeColorMenuHandler,
      revertColorMenuHandler,
      isColorPickerButtonVisible,
      noteSavedHandler,
      refSaveBtn,
      isAdvancedSectionExpanded,
      scrollToNotes,
      toggleAdvancedSection,
      isButtonsDividerNeeded,
      isShowTicketId,
      eventLogTooltip,
      eventLogTooltipContainer,
      descriptionMentionHandler,
    }

  }

})
